import {
  IonAlert,
  IonCol,
  IonContent,
  IonGrid,
  IonLabel,
  IonRow,
  useIonRouter,
} from "@ionic/react";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import { useRef, useState } from "react";
import * as Yup from "yup";
import { Input } from "../../components/elements/form/Input";
import Layout from "../../components/elements/Layout";
import { AxiosClient } from "../../services/api";
import { Button } from "../../components/elements/form/Button";
import { useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";

interface ResetPasswordData {
  password: string;
  passwordConfirmation: string;
}

export const ResetPasswordForm: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { token } = useParams<{ token?: string }>();
  const [canReset, setCanReset] = useState(false);
  const [open, setOpen] = useState(false);
  const router = useIonRouter();

  const { isLoading, data: resetToken } = useQuery(
    ["resetToken"],
    getResetToken,
    {
      enabled: !!token,
      refetchOnMount: true,
    }
  );

  async function getResetToken() {
    const response = await AxiosClient.get(`/users/reset-password/${token}`, {
      data: {
        orderBy: { id: "desc" },
      },
    });

    if (response.data) {
      const createdTime = moment(response.data.createdAt).toDate().getTime();
      const halfHour = moment().add(30, "minutes").toDate().getTime();

      if (createdTime <= halfHour && response.data.completed === false) {
        setCanReset(true);
      }
    }

    return response.data;
  }

  async function handleFormSubmit(data: ResetPasswordData) {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        password: Yup.string()
          .required("Password is required")
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
            "Passwords must be at least 8 characters and contain 1 uppercase, 1 lowercase and 1 number"
          ),
        passwordConfirmation: Yup.string().oneOf(
          [Yup.ref("password"), null],
          "Passwords must match"
        ),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      await AxiosClient.post(`/users/reset-password/${resetToken.id}`, {
        newPassword: data.password,
        userId: resetToken.user.id,
      });

      router.push("/");
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {} as any;

        err.inner.forEach((error) => {
          errorMessages[error.path!] = error.message;
        });

        formRef.current!.setErrors(errorMessages);
      }
    }
  }

  function resetPassword() {
    setOpen(false);
    router.push("/");
  }

  return (
    <Layout title="Reset Password" showBackButton showSchools={false}>
      <IonContent fullscreen>
        <>
          <IonAlert
            isOpen={open}
            onDidDismiss={() => resetPassword()}
            header="Password changed"
            message="Your password has been changed successfully. You can now login with your new password."
            buttons={["OK"]}
          />
          <IonGrid fixed={true}>
            {!isLoading && canReset ? (
              <Form ref={formRef} onSubmit={handleFormSubmit} initialData={{}}>
                <IonRow className="ion-justify-content-center">
                  <IonCol sizeMd="6">
                    <Input
                      name="password"
                      label="New Password"
                      type="password"
                      placeholder="Enter your new password"
                    />
                  </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-center">
                  <IonCol sizeMd="6">
                    <Input
                      name="passwordConfirmation"
                      label="Confirm Password"
                      type="password"
                      placeholder="Confirm your new password"
                    />
                  </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-center">
                  <IonCol sizeMd="6">
                    <Button label="Update Password" type="submit" />
                  </IonCol>
                </IonRow>
              </Form>
            ) : (
              <IonRow className="ion-justify-content-center">
                <IonCol sizeMd="5" sizeXs="6">
                  <IonLabel>
                    <h1>Invalid or expired reset password link</h1>
                  </IonLabel>
                </IonCol>
              </IonRow>
            )}
          </IonGrid>
        </>
      </IonContent>
    </Layout>
  );
};
