import React, { useRef, useEffect, useState } from "react";
import { useField } from "@unform/core";
import { IonInput, IonItem, IonLabel, IonNote } from "@ionic/react";

interface InputProps extends React.InputHTMLAttributes<HTMLIonInputElement> {
  name: string;
  label: string;
  fill?: "outline" | "solid";
  type?: "text" | "password" | "email";
}

export function Input({
  id,
  name,
  label,
  placeholder,
  fill,
  type,
}: InputProps) {
  const inputRef = useRef<HTMLIonInputElement>(null);
  const [isTouched, setIsTouched] = useState(false);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    if (defaultValue) {
      inputRef.current!.value = defaultValue;
    }

    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, registerField, defaultValue]);

  const markTouched = () => {
    setIsTouched(true);
  };

  return (
    <IonItem
      fill={fill}
      className={`${!!error === true && "ion-invalid"} ${
        isTouched && "ion-touched"
      } ${defaultValue && "item-has-value"}`}
    >
      <IonLabel position="floating">{label}</IonLabel>
      <IonInput
        id={id}
        ref={inputRef}
        defaultValue={defaultValue}
        type={type}
        placeholder={placeholder}
        onIonBlur={() => markTouched()}
      />
      {error && <IonNote slot="error">{error}</IonNote>}
    </IonItem>
  );
}
