import {
  IonBackButton,
  IonButtons,
  IonHeader,
  IonItem,
  IonList,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useContext, useMemo } from "react";
import { NavigationContext } from "../../contexts/NavigationContext";
import { AxiosClient } from "../../services/api";
import { useQuery } from "@tanstack/react-query";
import { Storage } from "@ionic/storage";
import { AuthContext } from "../../contexts/AuthContext";

interface TitleBarProps {
  title: string;
  showBackButton?: boolean;
  showSchools?: boolean;
}

export const TitleBar: React.FC<TitleBarProps> = ({
  title,
  showBackButton,
  showSchools = true,
}) => {
  const { school, setSchool } = useContext(NavigationContext);
  const { user } = useContext(AuthContext);
  const storage = useMemo(() => new Storage(), []);

  const { data: schools } = useQuery(["schools", user], getSchools);

  async function getSchools() {
    const data =
      user?.role === 1
        ? {}
        : {
            where: {
              users: {
                some: {
                  userId: user?.sub,
                },
              },
            },
          };

    const response = await AxiosClient.post("/schools/find-all", {
      ...data,
      orderBy: { name: "asc" },
    });

    return response.data;
  }

  async function handleChangeSchool(event: any) {
    await storage.create();
    await storage.set("school", event.target.value);
    setSchool(event.target.value);
  }

  return (
    <IonHeader style={{ marginBottom: "15px" }}>
      <IonToolbar color="primary">
        {showBackButton && (
          <IonButtons slot="start">
            <IonBackButton defaultHref="/app/home" />
          </IonButtons>
        )}
        <IonTitle>{title}</IonTitle>
        <IonButtons slot="end">
          <IonList color="primary" style={{ padding: 0 }}>
            {showSchools && schools && (
              <IonItem color="primary">
                <IonSelect
                  value={school || 1}
                  color="primary"
                  onIonChange={handleChangeSchool}
                  placeholder="Select school"
                >
                  {schools?.map((school: any) => (
                    <IonSelectOption key={school.id} value={school.id}>
                      {school.name}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>
            )}
          </IonList>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );
};
