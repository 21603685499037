import React, { useRef, useEffect } from "react";
import { useField } from "@unform/core";
import { IonLabel, IonNote } from "@ionic/react";
import "./File.css";

interface FileProps
  extends Omit<React.InputHTMLAttributes<HTMLIonInputElement>, "accept"> {
  id: string;
  name: string;
  label: string;
  buttonLabel: string;
  multiple?: boolean;
  accept?: string[];
  fill?: "outline" | "solid";
  type?: "text" | "password" | "email";
  onChange: (event: any) => void;
}

export function File({
  id,
  name,
  label,
  buttonLabel,
  onChange,
  accept = ["image/*", "application/pdf"],
  multiple = false,
}: FileProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    if (defaultValue) {
      inputRef.current!.value = defaultValue;
    }

    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "files",
    });
  }, [fieldName, registerField, defaultValue, name, multiple]);

  function onChangeHandler(e: any) {
    // get the files
    const files = e.target.files;

    // Process each file
    const allFiles = [] as any;
    for (var i = 0; i < files.length; i++) {
      const file = files[i];
      // Make new FileReader
      const reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        let fileInfo = {
          inputName: name,
          name: file.name,
          type: file.type,
          size: Math.round(file.size / 1000) + " kB",
          base64: reader.result,
          file: file,
        };

        // Push it to the state
        allFiles.push(fileInfo);

        // If all files have been proceed
        if (allFiles.length === files.length) {
          // Apply Callback function
          if (multiple) {
            onChange(allFiles);
          } else {
            onChange(allFiles[0]);
          }
        }
      }; // reader.onload
    }
  }

  return (
    <div>
      <IonLabel position="floating" className="ion-padding">
        {label}
      </IonLabel>
      <label className="custom-file-input" htmlFor={id}>
        {buttonLabel}
      </label>
      <input
        type="file"
        multiple={multiple}
        accept={accept.join(",")}
        onChange={onChangeHandler}
        id={id}
        ref={inputRef}
      />
      {error && <IonNote slot="error">{error}</IonNote>}
    </div>
  );
}
