import {
  IonAccordion,
  IonAccordionGroup,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonRefresher,
  IonRefresherContent,
  RefresherEventDetail,
  useIonRouter,
} from "@ionic/react";
import { create } from "ionicons/icons";
import { useQuery } from "@tanstack/react-query";
import { useContext, useRef } from "react";
import { Fab } from "../../components/elements/Fab";
import Layout from "../../components/elements/Layout";
import { NavigationContext } from "../../contexts/NavigationContext";
import { AxiosClient } from "../../services/api";
import moment from "moment-timezone";
import { AuthContext } from "../../contexts/AuthContext";

const Milestones: React.FC = () => {
  const router = useIonRouter();
  const { school } = useContext(NavigationContext);
  const { user } = useContext(AuthContext);
  const {
    isLoading,
    data: milestones,
    refetch,
  } = useQuery(["milestones", school], getMilestones);
  const accordionGroup = useRef<null | HTMLIonAccordionGroupElement>(null);

  async function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
    await refetch();
    event.detail.complete();
  }

  async function getMilestones() {
    const milestonesResponse = await AxiosClient.post("/milestones/find-all", {
      where: {
        schoolId: school,
      },
      select: {
        id: true,
        milestoneCategory: true,
        milestonesActivities: true,
      },
    });

    const milestones = milestonesResponse.data;

    return milestones;
  }

  return (
    <Layout title="Milestones" showBackButton={false} isLoading={isLoading}>
      {user && [1, 2].includes(user.role) && (
        <Fab
          onClick={() =>
            router.push("/app/milestones/form", "forward", "push", {
              unmount: true,
            })
          }
        />
      )}
      <IonContent fullscreen>
        <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <IonGrid fixed={true}>
          <IonAccordionGroup ref={accordionGroup} expand="inset" animated>
            {milestones?.map((milestone: any, index: any) => (
              <IonAccordion value={index} key={index}>
                <IonItem slot="header" color="light">
                  <IonLabel>
                    {milestone.milestoneCategory.name}{" "}
                    <IonIcon
                      icon={create}
                      style={{
                        padding: 1,
                        height: "16px",
                        color: "gray",
                      }}
                      onClick={() =>
                        router.push(
                          `/app/milestones/form/${milestone.id}`,
                          "forward",
                          "push",
                          {
                            unmount: true,
                          }
                        )
                      }
                    ></IonIcon>
                  </IonLabel>
                </IonItem>
                <IonList slot="content">
                  {milestone.milestonesActivities.length ? (
                    milestone.milestonesActivities.map(
                      (milestonesActivity: any) => (
                        <IonItem key={milestonesActivity.id} lines="none">
                          <IonLabel>
                            <p>{milestonesActivity.name}</p>
                            <p>
                              {milestonesActivity.deadline &&
                                moment(milestonesActivity.deadline)
                                  .tz("UTC")
                                  .format("MM/DD/YYYY")}
                            </p>
                          </IonLabel>
                        </IonItem>
                      )
                    )
                  ) : (
                    <IonItem lines="none">
                      <IonLabel>No Activities</IonLabel>
                    </IonItem>
                  )}
                </IonList>
              </IonAccordion>
            ))}
          </IonAccordionGroup>
        </IonGrid>
      </IonContent>
    </Layout>
  );
};

export default Milestones;
