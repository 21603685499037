import {
  IonAlert,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonText,
} from "@ionic/react";
import Logo from "../../assets/images/synkro_logo.png";
import { FormHandles } from "@unform/core";
import { useContext, useRef, useState } from "react";
import "./Login.css";
import { Input } from "../../components/elements/form/Input";
import { Form } from "@unform/web";
import * as yup from "yup";
import { AuthContext } from "../../contexts/AuthContext";

interface LoginData {
  email: string;
  password: string;
}

const Login: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { signIn } = useContext(AuthContext);
  const [unauthorized, setUnauthorized] = useState(false);

  function Copyright() {
    return (
      <IonRow className="ion-justify-content-center">
        <IonText>
          <IonItem href="#" lines="none">
            <IonLabel>
              {"Copyright © "} Synkro {new Date().getFullYear()} {"."}
            </IonLabel>
          </IonItem>
        </IonText>
      </IonRow>
    );
  }

  async function handleFormSubmit(data: LoginData) {
    try {
      formRef.current?.setErrors({});

      const schema = yup.object().shape({
        email: yup.string().email().required(),
        password: yup.string().required(),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      await signIn(data.email, data.password);
    } catch (error: any) {
      if (error instanceof yup.ValidationError) {
        const errorMessages = {} as any;
        error.inner.forEach((yupError: yup.CreateErrorOptions) => {
          errorMessages[yupError.path!] = yupError.message;
        });

        formRef.current?.setErrors(errorMessages);
      }
      if (error.response && error.response.status === 401) {
        setUnauthorized(true);
      }
    }
  }

  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="center-login">
          <IonAlert
            isOpen={unauthorized}
            onDidDismiss={() => setUnauthorized(false)}
            header="Login failed"
            message="Email adress or Password is incorrect"
            buttons={["OK"]}
          />
          <IonGrid>
            <IonRow className="ion-justify-content-center">
              <IonImg
                src={Logo}
                alt="logo"
                style={{ width: "300px", height: "100px" }}
              />
            </IonRow>
            <IonRow className="ion-justify-content-center">
              <IonText color="primary">
                <h1>Sign In</h1>
              </IonText>
            </IonRow>
            <Form ref={formRef} onSubmit={handleFormSubmit}>
              <IonRow className="ion-justify-content-center ion-row-login">
                <IonCol sizeLg="3" sizeXs="10">
                  <Input
                    fill="outline"
                    label="Email"
                    name="email"
                    type="email"
                    placeholder="Enter email"
                  />
                </IonCol>
              </IonRow>
              <IonRow className="ion-justify-content-center ion-row-login">
                <IonCol sizeLg="3" sizeXs="10">
                  <Input
                    fill="outline"
                    label="Password"
                    name="password"
                    type="password"
                    placeholder="Enter password"
                  />
                </IonCol>
              </IonRow>
              <IonRow className="ion-justify-content-center ion-row-login">
                <IonCol sizeLg="3" sizeXs="10">
                  <IonButton color="primary" expand="block" type="submit">
                    Sign In
                  </IonButton>
                </IonCol>
              </IonRow>
            </Form>
            <IonRow className="ion-justify-content-center">
              <IonCol sizeLg="3" sizeXs="10">
                <IonItem lines="none" routerLink="/forgot-password">
                  <IonLabel>Forgot password?</IonLabel>
                </IonItem>
              </IonCol>
            </IonRow>
            <Copyright />
          </IonGrid>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Login;
