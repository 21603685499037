import React, { useRef, useEffect } from "react";
import { useField } from "@unform/core";
import {
  IonItem,
  IonLabel,
  IonList,
  IonNote,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";

interface option {
  value: string | number;
  label: string;
}

interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  id?: string;
  name: string;
  label?: string;
  lines?: "none" | "full" | "inset" | "full";
  placeholder?: string;
  options?: option[];
  multiple?: boolean;
  onChange?: (event: any) => void;
  ref?: any;
}

export function Select({
  id,
  name,
  label,
  lines,
  placeholder,
  options,
  multiple,
  onChange,
}: SelectProps) {
  const inputRef = useRef<HTMLIonSelectElement>(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    if (defaultValue) {
      inputRef.current!.value = defaultValue;
    }

    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: (ref: any) => {
        return ref.value;
      },
    });
  }, [fieldName, registerField, defaultValue]);

  return (
    <IonList lines={lines}>
      <IonItem className={defaultValue ? "item-has-value" : ""}>
        {label && <IonLabel position="floating">{label}</IonLabel>}
        <IonSelect
          id={id}
          name={name}
          ref={inputRef}
          defaultValue={defaultValue}
          placeholder={placeholder}
          onIonChange={onChange}
          multiple={multiple}
        >
          {options?.map((option) => (
            <IonSelectOption key={option.value} value={option.value}>
              {option.label}
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonItem>
      {error && (
        <IonNote color="danger" slot="error">
          {error}
        </IonNote>
      )}
    </IonList>
  );
}
