import { IonContent, IonPage, IonSpinner } from "@ionic/react";
import { memo } from "react";
import { TitleBar } from "./TitleBar";
import styles from "./Layout.module.css";

interface LayoutProps {
  title: string;
  showBackButton?: boolean;
  showSchools?: boolean;
  children: React.ReactNode;
  isLoading?: boolean;
}

const Layout: React.FC<LayoutProps> = ({
  title,
  showBackButton,
  showSchools,
  isLoading = false,
  children,
}) => {
  return (
    <IonPage>
      <TitleBar
        title={title}
        showBackButton={showBackButton}
        showSchools={showSchools}
      />
      {isLoading ? (
        <IonContent fullscreen className="container-loading">
          <div className={styles.containerLoading}>
            <IonSpinner />
          </div>
        </IonContent>
      ) : (
        <>{children}</>
      )}
    </IonPage>
  );
};

export default memo(Layout);
