import {
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  useIonActionSheet,
} from "@ionic/react";
import { home, documentText, menu, diamond, calendar } from "ionicons/icons";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route, useHistory } from "react-router-dom";
import Home from "./Home/Home";
import { Documents } from "./Documents/Documents";
import { memo, useContext, useEffect } from "react";
import { Storage } from "@ionic/storage";
import { AuthContext } from "../contexts/AuthContext";
import Updates from "./Updates/Updates";
import Milestones from "./Milestones/Milestones";
import { MilestonesForm } from "./Milestones/MilestonesForm";
import { Profile } from "./Profile/Profile";
import { Users } from "./users/Users";
import { UsersForm } from "./users/UsersForm";
import { Schools } from "./Schools/Schools";
import { SchoolsForm } from "./Schools/SchoolsForm";
import { Categories } from "./Categories/Categories";
import { DocumentCategoriesForm } from "./Categories/DocumentCategoriesForm";
import { MilestoneCategoriesForm } from "./Categories/MilestoneCategoriesForm";
import { GeneralDocumentsForm } from "./Documents/DocumentGeneralForm";
import { FinancialDocumentsForm } from "./Documents/DocumentFinancialForm";
import { UpdatesForm } from "./Updates/UpdatesForm";

export const Tabs: React.FC = memo(() => {
  const [present] = useIonActionSheet();
  const history = useHistory();
  const { signOut } = useContext(AuthContext);

  const actionSheetOptions = {
    header: "More options",
    buttons: [
      {
        text: "Profile",
        data: {
          route: "/app/profile",
        },
      },
      {
        text: "Users",
        data: {
          route: "/app/users",
        },
      },
      {
        text: "Schools",
        data: {
          route: "/app/schools",
        },
      },
      {
        text: "Categories",
        data: {
          route: "/app/categories",
        },
      },
      {
        text: "Sign out",
        data: {
          route: "/logout",
        },
      },
    ],
    onDidDismiss: async ({ detail }: any) => {
      if (detail.data?.route) {
        if (detail.data.route === "/logout") {
          const cacheKeys = await caches.keys();
          for (let cacheKey of cacheKeys) caches.delete(cacheKey);
          signOut();
        }
        history.push(detail.data.route);
        history.go(0);
      }
    },
  };

  useEffect(() => {
    async function checkToken() {
      const storage = new Storage();
      await storage.create();
      const token = await storage.get("token");

      if (!token) {
        return history.push("/");
      }
    }
    checkToken();
  }, [history]);

  function showActionSheet(e: any) {
    present(actionSheetOptions);
  }

  return (
    <IonReactRouter>
      <IonTabs>
        <IonRouterOutlet>
          <Route exact path="/app/home" component={Home} />
          <Route exact path="/app/documents" component={Documents} />
          <Route exact path="/app/updates" component={Updates} />
          <Route exact path="/app/milestones" component={Milestones} />
          <Route exact path="/app/profile" component={Profile} />
          <Route exact path="/app/users" component={Users} />
          <Route exact path="/app/schools" component={Schools} />
          <Route exact path="/app/categories" component={Categories} />
          <Route exact path="/app/users/form/:userId?" component={UsersForm} />
          <Route
            exact
            path="/app/schools/form/:schoolId?"
            component={SchoolsForm}
          />
          <Route
            exact
            path="/app/milestones/form/:milestoneId?"
            component={MilestonesForm}
          />
          <Route
            exact
            path="/app/document-categories/form/:categoryId?"
            component={DocumentCategoriesForm}
          />
          <Route
            exact
            path="/app/milestone-categories/form/:categoryId?"
            component={MilestoneCategoriesForm}
          />
          <Route
            exact
            path="/app/general-documents/form/:documentId?"
            component={GeneralDocumentsForm}
          />
          <Route
            exact
            path="/app/updates/form/:updateId?"
            component={UpdatesForm}
          />
          <Route
            exact
            path="/app/financial-documents/form/:documentId?"
            component={FinancialDocumentsForm}
          />
          <Route exact path="/app" render={() => <Redirect to="/app/home" />} />
        </IonRouterOutlet>
        <IonTabBar slot="bottom">
          <IonTabButton tab="home" href="/app/home">
            <IonIcon icon={home} />
            <IonLabel>Home</IonLabel>
          </IonTabButton>
          <IonTabButton tab="documents" href="/app/documents">
            <IonIcon icon={documentText} />
            <IonLabel>Documents</IonLabel>
          </IonTabButton>
          <IonTabButton tab="updates" href="/app/updates">
            <IonIcon icon={diamond} />
            <IonLabel>Updates</IonLabel>
          </IonTabButton>
          <IonTabButton tab="milestones" href="/app/milestones">
            <IonIcon icon={calendar} />
            <IonLabel>Milestones</IonLabel>
          </IonTabButton>
          <IonTabButton tab="more" onClick={showActionSheet}>
            <IonIcon icon={menu} />
            <IonLabel>More</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </IonReactRouter>
  );
});
