import {
  IonAlert,
  IonCol,
  IonContent,
  IonGrid,
  IonRow,
  useIonRouter,
} from "@ionic/react";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import { useRef, useState } from "react";
import * as Yup from "yup";
import { Input } from "../../components/elements/form/Input";
import Layout from "../../components/elements/Layout";
import { AxiosClient } from "../../services/api";
import { Button } from "../../components/elements/form/Button";
import { useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { Select } from "../../components/elements/form/Select";

interface UsersData {
  id?: number;
  name: string;
  role: number;
  schoolsList?: number[];
  schools?: any;
  password: string;
}

export const UsersForm: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const router = useIonRouter();
  const { userId } = useParams<{ userId?: string }>();
  const [open, setOpen] = useState(false);
  const [tempPassword, setTempPassword] = useState("");
  const [userRole, setUserRole] = useState<number>();
  const [loading, setLoading] = useState(false);

  const { isFetching, data: user } = useQuery(["user"], getUser, {
    enabled: !!userId,
    staleTime: Infinity,
  });

  const { data: schools } = useQuery(["schoolsList"], getSchools);

  async function getSchools() {
    const response = await AxiosClient.get("/schools", {
      data: {
        orderBy: { id: "desc" },
      },
    });

    return response.data.map((school: any) => ({
      label: school.name,
      value: school.id,
    }));
  }

  const roleOptions = [
    { value: 1, label: "Super Admin" },
    { value: 2, label: "Manager" },
    { value: 3, label: "Investor" },
  ];

  async function getUser() {
    const response = await AxiosClient.post(`/users/find-one/${userId}`, {
      include: {
        schools: {
          select: {
            schoolId: true,
          },
        },
      },
    });

    setUserRole(response.data.role);

    return (
      (response.data.schoolsList = response.data.schools.map(
        (school: any) => school.schoolId
      )),
      response.data
    );
  }

  async function handleFormSubmit(data: UsersData) {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        name: Yup.string().required("Name is required"),
        email: Yup.string().required("Email is required"),
        role: Yup.string().required("Role is required"),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      if (typeof data.schoolsList === "object" && data.schoolsList.length > 0) {
        data.schools = {} as any;
        data.schools["create"] = data.schoolsList.map((school: any) => ({
          school: {
            connect: {
              id: school,
            },
          },
        }));
      }

      delete data.schoolsList;

      setLoading(true);

      if (userId) {
        await AxiosClient.put(`/users/${userId}`, data);
        router.push("/app/users", "forward", "push", {
          unmount: true,
        });
      } else {
        const userResponse = await AxiosClient.post("/users", data);
        setTempPassword(userResponse.data.password);
        setOpen(true);
      }
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {} as any;

        err.inner.forEach((error) => {
          errorMessages[error.path!] = error.message;
        });

        formRef.current!.setErrors(errorMessages);
      }
    }
  }

  function handleOk() {
    setOpen(false);
    router.push("/app/users", "forward", "push", {
      unmount: true,
    });
  }

  return (
    <Layout
      title="Users Form"
      showBackButton
      showSchools={false}
      isLoading={userId ? isFetching : false}
    >
      <IonContent fullscreen>
        <IonAlert
          isOpen={open}
          onDidDismiss={() => handleOk()}
          header="User created"
          subHeader="The User will receive an email with the login details"
          message={`Temporary password: ${tempPassword}`}
          backdropDismiss={false}
          buttons={[
            "OK",
            {
              text: "COPY",
              role: "copy",
              handler: () => {
                navigator.clipboard.writeText(tempPassword);
                return false;
              },
            },
          ]}
        />
        <IonGrid fixed={true}>
          <Form
            ref={formRef}
            onSubmit={handleFormSubmit}
            initialData={userId ? user : {}}
          >
            <IonRow className="ion-justify-content-center">
              <IonCol sizeMd="6">
                <Input name="name" label="Name" placeholder="Enter user name" />
              </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center">
              <IonCol sizeMd="6">
                <Input
                  name="email"
                  label="Email"
                  placeholder="Enter user email"
                />
              </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center">
              <IonCol sizeMd="6">
                <Select
                  name="role"
                  label="Role"
                  placeholder="Select user role"
                  onChange={(e: any) => setUserRole(e.target.value)}
                  options={roleOptions}
                />
              </IonCol>
            </IonRow>
            {userRole && userRole !== 1 && (
              <IonRow className="ion-justify-content-center">
                <IonCol sizeMd="6">
                  <Select
                    name="schoolsList"
                    label="Schools"
                    placeholder="Select user schools"
                    options={schools}
                    multiple={true}
                  />
                </IonCol>
              </IonRow>
            )}
            <IonRow className="ion-justify-content-center">
              <IonCol sizeMd="6">
                <Button label="Save" type="submit" isLoading={loading} />
              </IonCol>
            </IonRow>
          </Form>
        </IonGrid>
      </IonContent>
    </Layout>
  );
};
