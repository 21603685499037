import {
  IonAccordion,
  IonAccordionGroup,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonRefresher,
  IonRefresherContent,
  IonSegment,
  IonSegmentButton,
  RefresherEventDetail,
  useIonAlert,
  useIonRouter,
  useIonToast,
} from "@ionic/react";
import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect, useRef, useState } from "react";
import { Fab } from "../../components/elements/Fab";
import Layout from "../../components/elements/Layout";
import { NavigationContext } from "../../contexts/NavigationContext";
import { AxiosClient } from "../../services/api";
import moment from "moment";
import { trash, closeOutline } from "ionicons/icons";
import { AuthContext } from "../../contexts/AuthContext";

export const Documents: React.FC = () => {
  const router = useIonRouter();
  const { school } = useContext(NavigationContext);
  const { user } = useContext(AuthContext);
  const {
    isLoading,
    data: categories,
    refetch,
  } = useQuery(["documents", school], getDocuments);
  const [segment, setSegment] = useState("general");
  const [presentAlert] = useIonAlert();
  const [presentToast] = useIonToast();
  const accordionGroup = useRef<null | HTMLIonAccordionGroupElement>(null);

  async function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
    await refetch();
    event.detail.complete();
  }

  async function getDocuments() {
    const documentsCategoriesResponse = await AxiosClient.post(
      "/documents-categories/find-all",
      {
        include: {
          documents: {
            where: {
              schoolId: school,
            },
            include: {
              user: true,
            },
          },
        },
      }
    );

    const documentsCategories = documentsCategoriesResponse.data;

    const generalDocuments = documentsCategories.filter(
      (category: any) => category.type === 1
    );

    const financialsDocuments = documentsCategories.filter(
      (category: any) => category.type === 2
    );

    return { generalDocuments, financialsDocuments };
  }

  useEffect(() => {
    setTimeout(() => {
      if (!accordionGroup.current) {
        return;
      }
      accordionGroup.current!.value = "0";
    }, 500);
  }, [segment]);

  const deleteConfirm = (e: any) => {
    e.preventDefault();
    return presentAlert({
      header: "Delete document?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
        },
        {
          text: "OK",
          role: "confirm",
          handler: () => {
            deleteDocument(e.target.getAttribute("data-document"));
          },
        },
      ],
    });
  };

  async function deleteDocument(id: number) {
    try {
      await AxiosClient.delete(`/documents/${id}`);
      await refetch();
    } catch (err: any) {
      presentToast({
        message: "Error deleting document.",
        duration: 3000,
        color: "danger",
        buttons: [
          {
            icon: closeOutline,
            role: "cancel",
          },
        ],
      });
    }
  }

  return (
    <Layout title="Documents" isLoading={isLoading}>
      <IonContent fullscreen>
        {user && [1, 2].includes(user.role) && (
          <Fab
            onClick={() =>
              router.push(
                segment === "general"
                  ? "general-documents/form"
                  : "financial-documents/form",
                "forward",
                "push",
                {
                  unmount: true,
                }
              )
            }
          />
        )}
        <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <IonGrid fixed={true}>
          <IonSegment
            value={segment}
            onClick={(e: any) => setSegment(e.target.value)}
            style={{ marginBottom: "1rem" }}
          >
            <IonSegmentButton value="general">
              <IonLabel>General</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="financials">
              <IonLabel>Financials</IonLabel>
            </IonSegmentButton>
          </IonSegment>
          {segment === "general" && (
            <IonAccordionGroup ref={accordionGroup} expand="inset" animated>
              {categories?.generalDocuments.map(
                (documentCategory: any, index: any) => (
                  <IonAccordion value={index} key={index}>
                    <IonItem slot="header" color="light">
                      <IonLabel>{documentCategory.name} </IonLabel>
                    </IonItem>
                    <IonList slot="content">
                      {documentCategory.documents.length ? (
                        documentCategory.documents.map((document: any) => (
                          <IonItem
                            key={document.id}
                            href={document.url}
                            lines="none"
                            target="_blank"
                          >
                            <IonLabel>
                              {document.name}
                              <p>
                                {moment(document.createdAt).format(
                                  "MM/DD/YYYY"
                                )}
                              </p>
                              <p>{document.user?.name ?? ""}</p>
                            </IonLabel>
                            <IonIcon
                              icon={trash}
                              size="small"
                              style={{
                                padding: 1,
                                color: "gray",
                              }}
                              data-document={document.id}
                              onClick={(e: any) => deleteConfirm(e)}
                              slot="end"
                            ></IonIcon>
                          </IonItem>
                        ))
                      ) : (
                        <IonItem lines="none">
                          <IonLabel>No documents</IonLabel>
                        </IonItem>
                      )}
                    </IonList>
                  </IonAccordion>
                )
              )}
            </IonAccordionGroup>
          )}
          {segment === "financials" && (
            <IonAccordionGroup ref={accordionGroup} expand="inset" animated>
              {categories?.financialsDocuments.map(
                (documentCategory: any, index: any) => (
                  <IonAccordion value={index} key={documentCategory.id}>
                    <IonItem slot="header" color="light">
                      <IonLabel>{documentCategory.name}</IonLabel>
                    </IonItem>
                    <IonList slot="content">
                      {documentCategory.documents.length ? (
                        documentCategory.documents.map((document: any) => (
                          <IonItem
                            key={document.id}
                            href={document.url}
                            lines="none"
                            target="_blank"
                          >
                            <IonLabel>
                              {document.name}
                              <p>
                                {moment(document.createdAt).format(
                                  "MM/DD/YYYY"
                                )}
                              </p>
                              <p>{document.user?.name ?? ""}</p>
                            </IonLabel>
                            <IonIcon
                              icon={trash}
                              size="small"
                              style={{
                                padding: 1,
                                color: "gray",
                              }}
                              data-document={document.id}
                              onClick={(e: any) => deleteConfirm(e)}
                              slot="end"
                            />
                          </IonItem>
                        ))
                      ) : (
                        <IonItem lines="none">
                          <IonLabel>No documents</IonLabel>
                        </IonItem>
                      )}
                    </IonList>
                  </IonAccordion>
                )
              )}
            </IonAccordionGroup>
          )}
        </IonGrid>
      </IonContent>
    </Layout>
  );
};
