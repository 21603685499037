import {
  IonCol,
  IonContent,
  IonGrid,
  IonRow,
  useIonRouter,
} from "@ionic/react";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { Input } from "../../components/elements/form/Input";
import Layout from "../../components/elements/Layout";
import { AxiosClient } from "../../services/api";
import { parseJwt } from "../../helpers/parseJwt";
import { Button } from "../../components/elements/form/Button";
import { Storage } from "@ionic/storage";

interface ProfileData {
  id: number;
  name: string;
}

export const Profile: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [profile, setProfile] = useState<ProfileData>();
  const [isLoading, setIsLoading] = useState(false);
  const router = useIonRouter();

  useEffect(() => {
    async function getProfile() {
      const storage = new Storage();
      await storage.create();
      const token = await storage.get("token");
      const profileJwt = parseJwt(token);

      const profileData = {
        id: profileJwt.sub,
        name: profileJwt.name,
      };

      setProfile(profileData);

      formRef.current?.setData(profileData);
    }

    getProfile();
  }, []);

  async function handleFormSubmit(data: ProfileData) {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        name: Yup.string().required("Name is required"),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      setIsLoading(true);

      await AxiosClient.put(`/users/${profile?.id}`, data);

      return router.push("/app/home", "forward", "push", {
        unmount: true,
      });
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {} as any;

        err.inner.forEach((error) => {
          errorMessages[error.path!] = error.message;
        });

        formRef.current!.setErrors(errorMessages);
      }
    }
  }

  return (
    <Layout title="Profile" showBackButton showSchools={false}>
      <IonContent fullscreen>
        {profile !== null && (
          <>
            <IonGrid fixed={true}>
              <Form
                ref={formRef}
                onSubmit={handleFormSubmit}
                initialData={profile}
              >
                <IonRow className="ion-justify-content-center">
                  <IonCol sizeMd="6">
                    <Input
                      name="name"
                      label="Name"
                      placeholder="Enter your name"
                    />
                  </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-center">
                  <IonCol sizeMd="6">
                    <Button label="Save" type="submit" isLoading={isLoading} />
                  </IonCol>
                </IonRow>
              </Form>
            </IonGrid>
          </>
        )}
      </IonContent>
    </Layout>
  );
};
