import {
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonList,
  useIonAlert,
  useIonRouter,
  useIonToast,
} from "@ionic/react";
import { trash, closeOutline } from "ionicons/icons";
import { useQuery } from "@tanstack/react-query";
import { Fab } from "../../components/elements/Fab";
import Layout from "../../components/elements/Layout";
import { AxiosClient } from "../../services/api";
import { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";

export const Users: React.FC = () => {
  const router = useIonRouter();
  const { isLoading, data: users, refetch } = useQuery(["users"], getUsers);

  const roles = [null, "Super Admin", "Manager", "Investor"];
  const { user } = useContext(AuthContext);
  const [presentAlert] = useIonAlert();
  const [presentToast] = useIonToast();

  async function getUsers() {
    const response = await AxiosClient.get("/users", {
      data: {
        orderBy: { id: "desc" },
      },
    });
    return response.data;
  }

  const deleteConfirm = (e: any) =>
    presentAlert({
      header: "Delete user?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
        },
        {
          text: "OK",
          role: "confirm",
          handler: () => {
            deleteUser(e.target.getAttribute("data-user"));
          },
        },
      ],
    });

  async function deleteUser(id: number) {
    try {
      await AxiosClient.delete(`/users/${id}`);
      await refetch();
    } catch (err: any) {
      presentToast({
        message: "Error deleting user.",
        duration: 3000,
        color: "danger",
        buttons: [
          {
            icon: closeOutline,
            role: "cancel",
          },
        ],
      });
    }
  }

  return (
    <Layout
      title="Users"
      showBackButton
      showSchools={false}
      isLoading={isLoading}
    >
      <IonContent fullscreen>
        {user && [1, 2].includes(user.role) && (
          <Fab
            onClick={() =>
              router.push("users/form", "forward", "push", {
                unmount: true,
              })
            }
          />
        )}
        <IonGrid fixed={true}>
          <IonList>
            {users?.map((user: any) => (
              <IonItemSliding key={user.id}>
                <IonItem
                  key={user.id}
                  onClick={() =>
                    router.push(`users/form/${user.id}`, "forward", "push", {
                      unmount: true,
                    })
                  }
                  button
                  detail={true}
                >
                  <IonLabel>
                    {user.name}
                    <p>{user.email}</p>
                    <p>{roles[user.role]}</p>
                  </IonLabel>
                </IonItem>
                <IonItemOptions>
                  <IonItemOption
                    data-user={user.id}
                    onClick={(e: any) => deleteConfirm(e)}
                    color="danger"
                  >
                    <IonIcon
                      slot="icon-only"
                      icon={trash}
                      data-user={user.id}
                    ></IonIcon>
                  </IonItemOption>
                </IonItemOptions>
              </IonItemSliding>
            ))}
          </IonList>
        </IonGrid>
      </IonContent>
    </Layout>
  );
};
