import {
  IonContent,
  IonGrid,
  IonItem,
  IonLabel,
  IonList,
  IonSegment,
  IonSegmentButton,
  useIonRouter,
} from "@ionic/react";
import { useQuery } from "@tanstack/react-query";
import { useContext, useState } from "react";
import { Fab } from "../../components/elements/Fab";
import Layout from "../../components/elements/Layout";
import { AuthContext } from "../../contexts/AuthContext";
import { AxiosClient } from "../../services/api";

export const Categories: React.FC = () => {
  const router = useIonRouter();
  const { user } = useContext(AuthContext);
  const { isLoading, data: categories } = useQuery(
    ["categories"],
    getCategories
  );
  const [segment, setSegment] = useState("documents");

  async function getCategories() {
    const milestonesCategoriesResponse = await AxiosClient.get(
      "/milestones-categories",
      {
        data: {
          orderBy: { id: "desc" },
        },
      }
    );

    const DocumentsCategoriesResponse = await AxiosClient.get(
      "/documents-categories",
      {
        data: {
          orderBy: { id: "desc" },
        },
      }
    );
    return {
      milestonesCategories: milestonesCategoriesResponse.data,
      documentsCategories: DocumentsCategoriesResponse.data,
    };
  }

  return (
    <Layout
      title="Categories"
      showBackButton
      showSchools={false}
      isLoading={isLoading}
    >
      <IonContent fullscreen>
        {user && [1, 2].includes(user.role) && (
          <Fab
            onClick={() =>
              router.push(
                segment === "documents"
                  ? "document-categories/form"
                  : "milestone-categories/form",
                "forward",
                "push",
                {
                  unmount: true,
                }
              )
            }
          />
        )}
        <IonGrid fixed={true}>
          <IonSegment
            value={segment}
            onClick={(e: any) => setSegment(e.target.value)}
          >
            <IonSegmentButton value="documents">
              <IonLabel>Documents</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="milestones">
              <IonLabel>Milestones</IonLabel>
            </IonSegmentButton>
          </IonSegment>
          {segment === "documents" && (
            <IonList>
              {categories?.documentsCategories.map((documentCategory: any) => (
                <IonItem
                  key={documentCategory.id}
                  onClick={() =>
                    router.push(
                      `document-categories/form/${documentCategory.id}`,
                      "forward",
                      "push",
                      {
                        unmount: true,
                      }
                    )
                  }
                  button
                  detail={true}
                >
                  <IonLabel>
                    {documentCategory.name}{" "}
                    <p>
                      {documentCategory.type === 1 ? "General" : "Financial"}
                    </p>
                  </IonLabel>
                </IonItem>
              ))}
            </IonList>
          )}
          {segment === "milestones" && (
            <IonList>
              {categories?.milestonesCategories.map(
                (milestoneCategory: any) => (
                  <IonItem
                    key={milestoneCategory.id}
                    onClick={() =>
                      router.push(
                        `milestone-categories/form/${milestoneCategory.id}`,
                        "forward",
                        "push",
                        {
                          unmount: true,
                        }
                      )
                    }
                    button
                    detail={true}
                  >
                    <IonLabel>{milestoneCategory.name}</IonLabel>
                  </IonItem>
                )
              )}
            </IonList>
          )}
        </IonGrid>
      </IonContent>
    </Layout>
  );
};
