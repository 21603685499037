import React, { useRef, useEffect, useState } from "react";
import { useField } from "@unform/core";
import moment from "moment";
import {
  IonDatetime,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonNote,
} from "@ionic/react";

interface DatetimeProps extends React.InputHTMLAttributes<HTMLIonInputElement> {
  id: string;
  name: string;
  label: string;
  fill?: "outline" | "solid";
}

export function Datetime({
  id,
  name,
  label,
  placeholder,
  fill,
}: DatetimeProps) {
  const inputRef = useRef<HTMLIonInputElement>(null);
  const datetimeRef = useRef<HTMLIonDatetimeElement>(null);
  const [dateValue, setDateValue] = useState<string | null>();
  const [isTouched, setIsTouched] = useState(false);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    if (defaultValue) {
      inputRef.current!.value = defaultValue;
      datetimeRef.current!.value = moment(
        defaultValue,
        "MM/DD/YYYY"
      ).toISOString();
    }

    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, registerField, defaultValue]);

  const markTouched = () => {
    setIsTouched(true);
  };

  return (
    <>
      <IonItem
        fill={fill}
        className={`${!!error === true && "ion-invalid"} ${
          isTouched && "ion-touched"
        } ${defaultValue && "item-has-value"}`}
      >
        <IonLabel position="floating">{label}</IonLabel>
        <IonInput
          id={id}
          ref={inputRef}
          value={dateValue}
          placeholder={placeholder}
          onIonBlur={() => markTouched()}
        />
      </IonItem>
      <IonModal trigger={id} keepContentsMounted={true}>
        <IonDatetime
          id={id || "datetime"}
          ref={datetimeRef}
          color="primary"
          size="cover"
          placeholder={placeholder}
          onIonChange={(e: any) =>
            setDateValue(moment(e.detail.value).format("MM/DD/YYYY"))
          }
          showDefaultButtons={true}
          showDefaultTimeLabel={false}
          style={{ color: "#000" }}
        ></IonDatetime>
      </IonModal>
      {error && <IonNote slot="error">{error}</IonNote>}
    </>
  );
}
