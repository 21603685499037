import { IonItem, IonLabel, IonNote, IonToggle } from "@ionic/react";
import { useEffect, useRef } from "react";
import { useField } from "@unform/core";

interface ToggleProps extends React.InputHTMLAttributes<HTMLIonInputElement> {
  name: string;
  label?: string;
}

export function Toggle({ name, label }: ToggleProps) {
  const inputRef = useRef<HTMLIonToggleElement>(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    if (defaultValue) {
      inputRef.current!.checked = defaultValue;
    }

    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: (ref) => {
        return ref.checked;
      },
      setValue(ref, value) {
        ref.checked = value;
      },
    });
  }, [fieldName, registerField, defaultValue]);

  return (
    <IonItem>
      <IonLabel>{label}</IonLabel>
      <IonToggle ref={inputRef} slot="end" name={name}></IonToggle>
      {error && <IonNote slot="error">{error}</IonNote>}
    </IonItem>
  );
}
