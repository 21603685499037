import {
  IonCol,
  IonContent,
  IonGrid,
  IonRow,
  useIonRouter,
} from "@ionic/react";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import { useContext, useRef, useState } from "react";
import * as Yup from "yup";
import { Input } from "../../components/elements/form/Input";
import Layout from "../../components/elements/Layout";
import { AxiosClient } from "../../services/api";
import { Button } from "../../components/elements/form/Button";
import { useParams } from "react-router";
import { File } from "../../components/elements/form/File";
import { NavigationContext } from "../../contexts/NavigationContext";
import { AuthContext } from "../../contexts/AuthContext";

interface UpdatesData {
  id?: number;
  name: string;
  description?: string;
  file: File;
}

export const UpdatesForm: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { school } = useContext(NavigationContext);
  const { user } = useContext(AuthContext);
  const router = useIonRouter();
  const { documentId } = useParams<{ documentId?: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [files, setFiles] = useState<any>();

  async function handleFormSubmit(data: UpdatesData) {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        name: Yup.string().required("Name is required"),
        files: Yup.mixed().test(
          "fileType",
          "The file format is invalid",
          (value) => {
            if (!value.length) return false;
            return value[0].type.startsWith("image/");
          }
        ),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const updatesData = {
        title: data.name,
        description: data.description ?? null,
        school: {
          connect: { id: school },
        },
        user: {
          connect: { id: user?.sub },
        },
        documentsRaw: [],
      } as any;

      for (const index in files) {
        updatesData.documentsRaw[index] = {
          filename: files[index].name,
          base64Binary: files[index].base64,
        };
      }

      setLoading(true);

      if (documentId) {
        await AxiosClient.put(`/posts/${documentId}`, updatesData);
      } else {
        await AxiosClient.post("/posts", updatesData);
      }

      return router.push("/app/updates", "forward", "push", {
        unmount: true,
      });
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {} as any;

        err.inner.forEach((error) => {
          errorMessages[error.path!] = error.message;
        });

        formRef.current!.setErrors(errorMessages);
      }
    }
  }

  return (
    <Layout title="Updates Form" showBackButton showSchools={false}>
      <IonContent fullscreen>
        <IonGrid fixed={true}>
          <Form
            ref={formRef}
            onSubmit={handleFormSubmit}
            initialData={documentId ? document : {}}
          >
            <IonRow class="ion-justify-content-center">
              <IonCol sizeMd="6">
                <Input
                  name="name"
                  label="Name"
                  placeholder="Enter post title"
                />
              </IonCol>
            </IonRow>
            <IonRow class="ion-justify-content-center">
              <IonCol sizeMd="6">
                <Input
                  name="description"
                  label="Description"
                  placeholder="Enter post description"
                />
              </IonCol>
            </IonRow>
            <IonRow
              class="ion-justify-content-center"
              style={{ marginTop: "20px" }}
            >
              <IonCol sizeMd="6">
                <File
                  id="file"
                  name="files"
                  label="Files"
                  multiple
                  accept={["image/*"]}
                  buttonLabel="Select file(s)"
                  onChange={(e) => setFiles(e)}
                />
              </IonCol>
            </IonRow>
            <IonRow
              class="ion-justify-content-center"
              style={{ marginTop: "20px" }}
            >
              <IonCol sizeMd="6">
                <Button label="Upload" type="submit" isLoading={loading} />
              </IonCol>
            </IonRow>
          </Form>
        </IonGrid>
      </IonContent>
    </Layout>
  );
};
