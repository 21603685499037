import {
  IonCol,
  IonContent,
  IonGrid,
  IonRow,
  useIonRouter,
} from "@ionic/react";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import { useRef, useState } from "react";
import * as Yup from "yup";
import { Input } from "../../components/elements/form/Input";
import Layout from "../../components/elements/Layout";
import { AxiosClient } from "../../services/api";
import { Button } from "../../components/elements/form/Button";
import { useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { Select } from "../../components/elements/form/Select";

interface DocumentCategoryData {
  id?: number;
  name: string;
  type: number;
}

export const DocumentCategoriesForm: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const router = useIonRouter();
  const { categoryId } = useParams<{ categoryId?: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const { isFetching, data: category } = useQuery(
    ["document-category"],
    getCategory,
    {
      enabled: !!categoryId,
      staleTime: Infinity,
    }
  );

  const typeOptions = [
    { value: 1, label: "General" },
    { value: 2, label: "Finacial" },
  ];

  async function getCategory() {
    const response = await AxiosClient.get(
      `/documents-categories/${categoryId}`
    );

    return response.data;
  }

  async function handleFormSubmit(data: DocumentCategoryData) {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        name: Yup.string().required("Name is required"),
        type: Yup.string().required("Type is required"),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      setIsLoading(true);

      if (categoryId) {
        await AxiosClient.put(`/documents-categories/${categoryId}`, data);
      } else {
        await AxiosClient.post("/documents-categories", data);
      }

      return router.push("/app/categories", "forward", "push", {
        unmount: true,
      });
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {} as any;

        err.inner.forEach((error) => {
          errorMessages[error.path!] = error.message;
        });

        formRef.current!.setErrors(errorMessages);
      }
    }
  }

  return (
    <Layout
      title="Document Category Form"
      showBackButton
      showSchools={false}
      isLoading={categoryId ? isFetching : false}
    >
      <IonContent fullscreen>
        <IonGrid fixed={true}>
          <Form
            ref={formRef}
            onSubmit={handleFormSubmit}
            initialData={categoryId ? category : {}}
          >
            <IonRow className="ion-justify-content-center">
              <IonCol sizeMd="6">
                <Input
                  name="name"
                  label="Name"
                  placeholder="Enter category name"
                />
              </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center">
              <IonCol sizeMd="6">
                <Select name="type" label="Type" options={typeOptions} />
              </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center">
              <IonCol sizeMd="6">
                <Button label="Save" type="submit" isLoading={isLoading} />
              </IonCol>
            </IonRow>
          </Form>
        </IonGrid>
      </IonContent>
    </Layout>
  );
};
