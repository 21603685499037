import {
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonList,
  useIonAlert,
  useIonRouter,
  useIonToast,
} from "@ionic/react";
import { trash, closeOutline } from "ionicons/icons";
import { useQuery } from "@tanstack/react-query";
import { Fab } from "../../components/elements/Fab";
import Layout from "../../components/elements/Layout";
import { AxiosClient } from "../../services/api";
import { AuthContext } from "../../contexts/AuthContext";
import { useContext } from "react";

export const Schools: React.FC = () => {
  const router = useIonRouter();
  const [presentAlert] = useIonAlert();
  const [presentToast] = useIonToast();
  const { user } = useContext(AuthContext);
  const {
    isLoading,
    data: schools,
    refetch,
  } = useQuery(["schools"], getSchools);

  const deleteConfirm = (e: any) =>
    presentAlert({
      header: "Delete school?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
        },
        {
          text: "OK",
          role: "confirm",
          handler: () => {
            deleteSchool(e.target.getAttribute("data-school"));
          },
        },
      ],
    });

  async function getSchools() {
    const response = await AxiosClient.get("/schools", {
      data: {
        orderBy: { id: "desc" },
      },
    });
    return response.data;
  }

  async function deleteSchool(id: number) {
    try {
      await AxiosClient.delete(`/schools/${id}`);
      await refetch();
    } catch (err: any) {
      presentToast({
        message: "Error deleting school, check if there is associated data.",
        duration: 3000,
        color: "danger",
        buttons: [
          {
            icon: closeOutline,
            role: "cancel",
          },
        ],
      });
    }
  }

  return (
    <Layout
      title="Schools"
      showBackButton
      showSchools={false}
      isLoading={isLoading}
    >
      <IonContent fullscreen>
        {user && [1, 2].includes(user.role) && (
          <Fab
            onClick={() =>
              router.push("schools/form", "forward", "push", {
                unmount: true,
              })
            }
          />
        )}
        <IonGrid fixed={true}>
          <IonList>
            {schools?.map((school: any) => (
              <IonItemSliding key={school.id}>
                <IonItem
                  onClick={() =>
                    router.push(
                      `schools/form/${school.id}`,
                      "forward",
                      "push",
                      {
                        unmount: true,
                      }
                    )
                  }
                  button
                  detail={true}
                >
                  <IonLabel>
                    {school.name}
                    <p>{school.address}</p>
                  </IonLabel>
                </IonItem>
                <IonItemOptions>
                  <IonItemOption
                    data-school={school.id}
                    onClick={(e: any) => deleteConfirm(e)}
                    color="danger"
                  >
                    <IonIcon
                      slot="icon-only"
                      icon={trash}
                      data-school={school.id}
                    ></IonIcon>
                  </IonItemOption>
                </IonItemOptions>
              </IonItemSliding>
            ))}
          </IonList>
        </IonGrid>
      </IonContent>
    </Layout>
  );
};
