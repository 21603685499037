import { AxiosClient } from "../services/api";
import { createContext, useState, useEffect, useMemo } from "react";
import { Storage } from "@ionic/storage";

interface User {
  sub: number;
  email: string;
  name: string;
  role: number;
}

interface AuthContextType {
  isAuthenticated: boolean;
  user: User | null;
  signIn: (email: string, password: string, remember?: number) => Promise<void>;
  signOut: () => Promise<void>;
}

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthContext = createContext({} as AuthContextType);

export function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState<User | null>(null);
  const isAuthenticated = !!user;
  const storage = useMemo(() => new Storage(), []);

  useEffect(() => {
    async function checkToken() {
      await storage.create();
      const token = await storage.get("token");
      if (
        !token &&
        ![
          "/profile/create-password",
          "/",
          "/forgot-password",
          "/reset-password",
        ].includes(window.location.pathname) &&
        !window.location.pathname.includes("/reset-password")
      ) {
        window.history.replaceState({}, "Login", "/");
        window.history.go(0);
      } else {
        if (token) {
          setUser(parseJwt(token));
          if (["/"].includes(window.location.pathname)) {
            window.history.replaceState({}, "Dash", "/app");
            window.history.go(0);
          }
        }
      }
    }

    checkToken();
  }, [storage]);

  async function signIn(email: string, password: string) {
    await storage.create();
    const response = await AxiosClient.post("/auth/login", {
      email: email,
      password: password,
    });

    await storage.set("token", response.data.token);

    setUser(parseJwt(response.data.token));

    window.history.replaceState({}, "Home", "/app");
    window.history.go(0);
  }

  async function signOut() {
    await storage.remove("token");

    setUser(null);

    window.history.replaceState({}, "Login", "/");
    window.history.go(0);
  }

  function parseJwt(token: string) {
    return JSON.parse(atob(token.split(".")[1]));
  }

  return (
    <AuthContext.Provider value={{ user, isAuthenticated, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
}
