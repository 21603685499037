import { IonButton, IonSpinner } from "@ionic/react";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLIonButtonElement> {
  label: string;
  size?: "small" | "default" | "large";
  expand?: "block" | "full";
  fill?: "outline" | "solid";
  color?:
    | "primary"
    | "secondary"
    | "tertiary"
    | "success"
    | "warning"
    | "danger"
    | "light"
    | "medium"
    | "dark";
  type?: "submit" | "reset" | "button";
  isLoading?: boolean;
}

export function Button({
  id,
  label,
  fill,
  type = "button",
  expand = "block",
  size,
  color = "primary",
  isLoading = false,
  onClick,
}: ButtonProps) {
  return (
    <IonButton
      id={id}
      expand={expand}
      type={type}
      onClick={onClick}
      fill={fill}
      size={size}
      color={color}
      disabled={isLoading}
    >
      {isLoading ? <IonSpinner name="dots" /> : label}
    </IonButton>
  );
}
