import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/globals.css";

import { IonReactRouter } from "@ionic/react-router";
import { Route } from "react-router";
import Login from "./pages/Login/Login";
import { Tabs } from "./pages/Tabs";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthProvider } from "./contexts/AuthContext";
import { NavigationProvider } from "./contexts/NavigationContext";
import { ForgotPasswordForm } from "./pages/Profile/ForgotPassword";
import { ResetPasswordForm } from "./pages/Profile/ResetPassword";
import { useEffect } from "react";
import { Storage } from "@ionic/storage";

setupIonicReact();

const App: React.FC = () => {
  const App = () => {
    const queryClient = new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnReconnect: true,
          refetchOnMount: "always",
          refetchOnWindowFocus: false,
        },
      },
    });

    useEffect(() => {
      // Clear cache if version is different
      async function clearCache() {
        const storage = new Storage();
        await storage.create();
        const version = await storage.get("version");

        if (version !== process.env.REACT_APP_VERSION) {
          if (version !== null) {
            const cacheKeys = await caches.keys();
            for (let cacheKey of cacheKeys) caches.delete(cacheKey);
          }
          await storage.set("version", process.env.REACT_APP_VERSION);
        }
      }

      clearCache();
    }, []);

    return (
      <IonApp>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <NavigationProvider>
              <IonRouterOutlet>
                <Route exact path="/" component={Login} />
                <Route path="/app" component={Tabs} />
                <Route path="/forgot-password" component={ForgotPasswordForm} />
                <Route
                  path="/reset-password/:token"
                  component={ResetPasswordForm}
                />
              </IonRouterOutlet>
            </NavigationProvider>
          </AuthProvider>
        </QueryClientProvider>
      </IonApp>
    );
  };

  return (
    <IonReactRouter>
      <App />
    </IonReactRouter>
  );
};

export default App;
