import {
  IonAlert,
  IonCol,
  IonContent,
  IonGrid,
  IonRow,
  useIonRouter,
} from "@ionic/react";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import { useRef, useState } from "react";
import * as Yup from "yup";
import { Input } from "../../components/elements/form/Input";
import Layout from "../../components/elements/Layout";
import { AxiosClient } from "../../services/api";
import { Button } from "../../components/elements/form/Button";

interface ForgotPasswordData {
  email: string;
}

export const ForgotPasswordForm: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [open, setOpen] = useState(false);
  const router = useIonRouter();

  async function handleFormSubmit(data: ForgotPasswordData) {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        email: Yup.string().required("Email is required"),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      await AxiosClient.post("/users/reset-password-link", {
        email: data.email,
      });

      setOpen(true);
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {} as any;

        err.inner.forEach((error) => {
          errorMessages[error.path!] = error.message;
        });

        formRef.current!.setErrors(errorMessages);
      }
    }
  }

  function resetPassword() {
    setOpen(false);
    router.push("/");
  }

  return (
    <Layout title="Reset Password" showBackButton showSchools={false}>
      <IonContent fullscreen>
        <>
          <IonAlert
            isOpen={open}
            onDidDismiss={() => resetPassword()}
            header="Reset sent"
            message="If the email is registered, you will receive an email with instructions to reset your password."
            buttons={["OK"]}
          />
          <IonGrid fixed={true}>
            <Form ref={formRef} onSubmit={handleFormSubmit} initialData={{}}>
              <IonRow className="ion-justify-content-center">
                <IonCol sizeMd="6">
                  <Input
                    name="email"
                    label="Email"
                    placeholder="Enter your email"
                  />
                </IonCol>
              </IonRow>
              <IonRow className="ion-justify-content-center">
                <IonCol sizeMd="6">
                  <Button label="Send reset link" type="submit" />
                </IonCol>
              </IonRow>
            </Form>
          </IonGrid>
        </>
      </IonContent>
    </Layout>
  );
};
