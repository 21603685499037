import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonSlide,
  IonSlides,
  RefresherEventDetail,
  useIonActionSheet,
  useIonAlert,
  useIonRouter,
  useIonToast,
} from "@ionic/react";
import { useQuery } from "@tanstack/react-query";
import { ellipsisVertical, closeOutline, trash } from "ionicons/icons";
import { useContext } from "react";
import { Fab } from "../../components/elements/Fab";
import Layout from "../../components/elements/Layout";
import { NavigationContext } from "../../contexts/NavigationContext";
import { AxiosClient } from "../../services/api";
import moment from "moment-timezone";
import { AuthContext } from "../../contexts/AuthContext";

const Updates: React.FC = () => {
  const router = useIonRouter();
  const { school } = useContext(NavigationContext);
  const { user } = useContext(AuthContext);
  const [presentAlert] = useIonAlert();
  const [presentToast] = useIonToast();
  const [present] = useIonActionSheet();
  const {
    isLoading,
    data: updates,
    refetch,
  } = useQuery(["updates", school], getUpdates);

  async function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
    await refetch();
    event.detail.complete();
  }

  function showActionSheet(e: any) {
    present({
      header: "Options",
      buttons: [
        {
          text: "Delete",
          role: "destructive",
          icon: trash,
          data: e,
        },
        {
          text: "Cancel",
          icon: closeOutline,
          role: "cancel",
        },
      ],
      onDidDismiss: ({ detail }: any) => {
        if (detail.role === "destructive") {
          presentAlert({
            header: "Delete post?",
            buttons: [
              {
                text: "Cancel",
                role: "cancel",
              },
              {
                text: "OK",
                role: "confirm",
                handler: () => {
                  deletePost(detail.data?.target.getAttribute("data-id"));
                },
              },
            ],
          });
        }
      },
    });
  }

  async function deletePost(id: number) {
    try {
      await AxiosClient.delete(`/posts/${id}`);
      await refetch();
    } catch (err: any) {
      presentToast({
        message: "Error deleting post",
        duration: 3000,
        color: "danger",
        buttons: [
          {
            icon: closeOutline,
            role: "cancel",
          },
        ],
      });
    }
  }

  async function getUpdates() {
    const updatesResponse = await AxiosClient.post("/posts/find-all", {
      where: {
        schoolId: school,
      },
      select: {
        id: true,
        title: true,
        description: true,
        createdAt: true,
        documents: {
          include: {
            user: true,
          },
        },
      },
      orderBy: {
        createdAt: "desc",
      },
    });

    return updatesResponse.data;
  }

  const slideOpts = {
    initialSlide: 1,
    speed: 400,
    grabCursor: true,
  };

  return (
    <Layout title="Updates" isLoading={isLoading}>
      <IonContent fullscreen>
        <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        {user && [1, 2].includes(user.role) && (
          <Fab
            onClick={() =>
              router.push("/app/updates/form", "forward", "push", {
                unmount: true,
              })
            }
          />
        )}
        <IonGrid fixed={true}>
          {updates?.map((update: any) => (
            <IonRow key={update.id} className="ion-justify-content-center">
              <IonCol style={{ maxWidth: "600px" }}>
                <IonCard>
                  <div
                    style={{
                      background: "black",
                      display: "flex",
                    }}
                  >
                    {update.documents.length === 1 ? (
                      <IonImg
                        alt={update.title}
                        src={update.documents[0]?.url}
                      />
                    ) : (
                      <IonSlides pager={true} options={slideOpts}>
                        {update.documents.map((document: any) => (
                          <IonSlide key={document.id}>
                            <IonImg alt={update.title} src={document.url} />
                          </IonSlide>
                        ))}
                      </IonSlides>
                    )}
                  </div>
                  <IonCardHeader>
                    <IonCardTitle>
                      <IonRow>
                        <IonCol>{update.title}</IonCol>
                        {user && [1, 2].includes(user.role) && (
                          <IonCol size="auto">
                            <IonIcon
                              data-id={update.id}
                              onClick={showActionSheet}
                              icon={ellipsisVertical}
                              size="md"
                            />
                          </IonCol>
                        )}
                      </IonRow>
                      <IonCardSubtitle>
                        <IonRow>
                          <IonCol>
                            {update.documents[0].user?.name
                              ? `${update.documents[0].user?.name} -${" "}`
                              : ""}
                            {moment(update.createdAt)
                              .tz("UTC")
                              .format("MM/DD/YYYY")}
                          </IonCol>
                        </IonRow>
                      </IonCardSubtitle>
                    </IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonRow>
                      <IonCol>{update.description}</IonCol>
                    </IonRow>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
          ))}
        </IonGrid>
      </IonContent>
    </Layout>
  );
};

export default Updates;
