import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonItem,
  IonLabel,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonSlide,
  IonSlides,
  RefresherEventDetail,
} from "@ionic/react";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import Layout from "../../components/elements/Layout";
import { AxiosClient } from "../../services/api";
import moment from "moment-timezone";
import { AuthContext } from "../../contexts/AuthContext";
import { NavigationContext } from "../../contexts/NavigationContext";

const Home: React.FC = () => {
  const { user } = useContext(AuthContext);
  const { school } = useContext(NavigationContext);
  const {
    isLoading,
    data: dashboard,
    refetch,
  } = useQuery(["dashboard", school], getDashboard, { enabled: !!school });

  async function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
    await refetch();
    event.detail.complete();
  }

  async function getDashboard() {
    const updatesResponse = await AxiosClient.post("/posts/find-all", {
      where: {
        schoolId: school,
      },
      select: {
        id: true,
        title: true,
        description: true,
        createdAt: true,
        documents: {
          include: {
            user: true,
          },
        },
        school: true,
      },
      orderBy: {
        createdAt: "desc",
      },
    });

    const documentsResponse = await AxiosClient.post("/documents/find-all", {
      where: {
        schoolId: school,
        postId: null,
        documentCategoryId: { not: null },
      },
      include: {
        documentCategory: true,
        school: true,
        user: true,
      },
      orderBy: {
        updatedAt: "desc",
      },
    });

    const data = [
      ...updatesResponse.data.map((update: any) => ({
        ...update,
        dataType: 1,
      })),
      ...documentsResponse.data.map((document: any) => ({
        ...document,
        dataType: 2,
      })),
    ];

    const dataSorted = data.sort((a: any, b: any) => {
      return moment(b.createdAt).diff(moment(a.createdAt));
    });

    return dataSorted;
  }

  const slideOpts = {
    initialSlide: 1,
    speed: 400,
    grabCursor: true,
    cubeEffect: {
      shadow: true,
      slideShadows: true,
      shadowOffset: 20,
      shadowScale: 0.94,
    },
  };

  return (
    <Layout title="Home" isLoading={isLoading} showSchools={true}>
      <IonContent fullscreen>
        <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <IonGrid fixed={true}>
          <IonRow
            className="ion-justify-content-center"
            style={{ marginBottom: "25px", marginLeft: "10px" }}
          >
            <IonCol sizeMd="6" sizeXs="12">
              <IonLabel>
                <h1>Welcome Back, {user?.name}</h1>
              </IonLabel>
            </IonCol>
          </IonRow>
          {dashboard?.map((dashData: any) => (
            <IonRow
              key={dashData.id}
              className="ion-justify-content-center"
              style={{ marginBottom: "20px" }}
            >
              {dashData.dataType === 1 ? (
                <IonCol style={{ maxWidth: "600px" }}>
                  <IonCard>
                    <div
                      style={{
                        background: "black",
                        display: "flex",
                      }}
                    >
                      {dashData.documents.length === 1 ? (
                        <IonImg
                          alt={dashData.title}
                          src={dashData.documents[0]?.url}
                        />
                      ) : (
                        <IonSlides pager={true} options={slideOpts}>
                          {dashData.documents.map((document: any) => (
                            <IonSlide key={document.id}>
                              <IonImg alt={dashData.title} src={document.url} />
                            </IonSlide>
                          ))}
                        </IonSlides>
                      )}
                    </div>
                    <IonCardHeader>
                      <IonCardTitle>
                        <IonRow>
                          <IonCol>{dashData.title}</IonCol>
                          <IonCol size="auto">
                            <IonChip>{dashData.school.name}</IonChip>
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol>
                            <IonCardSubtitle>
                              {dashData.documents[0].user?.name
                                ? `${dashData.documents[0].user?.name} -${" "}`
                                : ""}
                              {moment(dashData.createdAt)
                                .tz("UTC")
                                .format("MM/DD/YYYY")}
                            </IonCardSubtitle>
                          </IonCol>
                        </IonRow>
                      </IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                      <IonRow>
                        <IonCol>{dashData.description}</IonCol>
                      </IonRow>
                    </IonCardContent>
                  </IonCard>
                </IonCol>
              ) : (
                <IonCol key={dashData.id} style={{ maxWidth: "610px" }}>
                  <IonItem href={dashData.url} target="_blank">
                    <IonLabel>
                      <h1>{dashData.name}</h1>
                      <p>{dashData.user?.name ?? ""} </p>
                      <p>
                        Type:{" "}
                        {dashData.documentCategory.type === 1
                          ? "General"
                          : "Financial"}
                      </p>
                      <p>Category: {dashData.documentCategory.name}</p>
                    </IonLabel>
                    <IonChip>{dashData.school.name}</IonChip>
                  </IonItem>
                </IonCol>
              )}
            </IonRow>
          ))}
        </IonGrid>
      </IonContent>
    </Layout>
  );
};

export default Home;
