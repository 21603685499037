import {
  IonCol,
  IonContent,
  IonGrid,
  IonRow,
  useIonRouter,
} from "@ionic/react";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import { useRef, useState } from "react";
import * as Yup from "yup";
import { Input } from "../../components/elements/form/Input";
import Layout from "../../components/elements/Layout";
import { AxiosClient } from "../../services/api";
import { Button } from "../../components/elements/form/Button";
import { useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { Toggle } from "../../components/elements/form/Toggle";

interface SchoolData {
  id?: number;
  name: string;
  underDevelopment: boolean;
  address?: number;
}

export const SchoolsForm: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const router = useIonRouter();
  const [isLoading, setIsLoading] = useState(false);
  const { schoolId } = useParams<{ schoolId?: string }>();

  const { data: school, isFetching } = useQuery(["school"], getSchool, {
    enabled: !!schoolId,
    staleTime: Infinity,
  });

  async function getSchool() {
    const response = await AxiosClient.get(`/schools/${schoolId}`);

    return response.data;
  }

  async function handleFormSubmit(data: SchoolData) {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        name: Yup.string().required("Name is required"),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      setIsLoading(true);

      if (schoolId) {
        await AxiosClient.put(`/schools/${schoolId}`, data);
      } else {
        await AxiosClient.post("/schools", data);
      }

      return router.push("/app/schools", "forward", "push", {
        unmount: true,
      });
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {} as any;

        err.inner.forEach((error) => {
          errorMessages[error.path!] = error.message;
        });

        formRef.current!.setErrors(errorMessages);
      }
    }
  }

  return (
    <Layout
      title="School Form"
      showBackButton
      showSchools={false}
      isLoading={schoolId ? isFetching : false}
    >
      <IonContent fullscreen>
        <IonGrid>
          <Form
            ref={formRef}
            onSubmit={handleFormSubmit}
            initialData={schoolId ? school : {}}
          >
            <IonRow className="ion-justify-content-center">
              <IonCol sizeMd="6">
                <Input
                  name="name"
                  label="Name"
                  placeholder="Enter school name"
                />
              </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center">
              <IonCol sizeMd="6">
                <Input
                  name="address"
                  label="Address"
                  placeholder="Enter school address"
                />
              </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center">
              <IonCol sizeMd="6">
                <Toggle name="underDevelopment" label="Under Development" />
              </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center">
              <IonCol sizeMd="6">
                <Button label="Save" type="submit" isLoading={isLoading} />
              </IonCol>
            </IonRow>
          </Form>
        </IonGrid>
      </IonContent>
    </Layout>
  );
};
