import { Storage } from "@ionic/storage";
import { createContext, useState, useEffect } from "react";

interface SchoolsType {
  id: number;
  name: string;
}

interface NavigationContextType {
  title: string;
  school: number;
  schools: SchoolsType[];
  activeNav: number;
  setSchool: (school: number) => void;
  setTitle: (title: string) => void;
  setSchools: (schools: SchoolsType[]) => void;
  setActiveNav: (activeNav: number) => void;
}

interface NavigationContextProps {
  children: React.ReactNode;
}

export const NavigationContext = createContext({} as NavigationContextType);

export function NavigationProvider({ children }: NavigationContextProps) {
  const [title, setTitle] = useState("");
  const [school, setSchool] = useState(1);
  const [schools, setSchools] = useState<SchoolsType[]>([]);
  const [activeNav, setActiveNav] = useState(0);

  useEffect(() => {
    async function getSchool() {
      const storage = new Storage();
      await storage.create();
      const schoolCookie = await storage.get("school");

      if (schoolCookie) {
        setSchool(parseInt(schoolCookie));
      }
    }

    getSchool();
  }, []);

  return (
    <NavigationContext.Provider
      value={{
        title,
        school,
        setSchool,
        setTitle,
        schools,
        setSchools,
        activeNav,
        setActiveNav,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
}
