import axios from "axios";
import { Storage } from "@ionic/storage";

export const AxiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

AxiosClient.interceptors.request.use(
  async function (config) {
    const storage = new Storage();
    await storage.create();
    const token = await storage.get("token");

    if (token) {
      config.headers!.Authorization = `Bearer ${token}`;
      config.headers!["Content-Type"] = "application/json";
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
