import {
  IonCol,
  IonContent,
  IonGrid,
  IonRow,
  useIonRouter,
} from "@ionic/react";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import { useContext, useRef, useState } from "react";
import * as Yup from "yup";
import { Input } from "../../components/elements/form/Input";
import Layout from "../../components/elements/Layout";
import { AxiosClient } from "../../services/api";
import { Button } from "../../components/elements/form/Button";
import { useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { Select } from "../../components/elements/form/Select";
import { File } from "../../components/elements/form/File";
import { NavigationContext } from "../../contexts/NavigationContext";
import { AuthContext } from "../../contexts/AuthContext";

interface FinancialDocumentsData {
  id?: number;
  name: string;
  documentCategoryId: string;
}

export const FinancialDocumentsForm: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { school } = useContext(NavigationContext);
  const { user } = useContext(AuthContext);
  const router = useIonRouter();
  const { documentId } = useParams<{ documentId?: string }>();
  const [file, setFile] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { isLoading, data: documentCategories } = useQuery(
    ["document-categories-financials"],
    getGeneralDocument
  );

  async function getGeneralDocument() {
    const documentCategoriesResponse = await AxiosClient.post(
      "documents-categories/find-all",
      {
        where: {
          type: 2,
        },
      }
    );

    return documentCategoriesResponse.data.map((category: any) => ({
      value: category.id,
      label: category.name,
    }));
  }

  async function handleFormSubmit(data: FinancialDocumentsData) {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        documentCategoryId: Yup.string().required("Category is required"),
        name: Yup.string().required("Name is required"),
        file: Yup.mixed().test(
          "fileType",
          "The file format is invalid",
          (value) => {
            if (!value.length) return false;
            return (
              [
                ".doc",
                ".docx",
                "application/msword",
                "application/pdf",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              ].includes(value[0].type) || value[0].type.startsWith("image/")
            );
          }
        ),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      setLoading(true);

      const documentData = {
        filename: file?.name,
        name: data.name,
        type: 2,
        base64Binary: file.base64,
        documentCategory: {
          connect: { id: parseInt(data.documentCategoryId) },
        },
        school: {
          connect: { id: school },
        },
        user: {
          connect: { id: user?.sub },
        },
      };

      if (documentId) {
        await AxiosClient.put(`/documents/${documentId}`, documentData);
      } else {
        await AxiosClient.post("/documents", documentData);
      }

      return router.push("/app/documents", "forward", "push", {
        unmount: true,
      });
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {} as any;

        err.inner.forEach((error) => {
          errorMessages[error.path!] = error.message;
        });

        formRef.current!.setErrors(errorMessages);
      }
    }
  }

  return (
    <Layout
      title="Financial Documents Form"
      showBackButton
      showSchools={false}
      isLoading={documentId ? isLoading : false}
    >
      <IonContent fullscreen>
        <IonGrid fixed={true}>
          <Form
            ref={formRef}
            onSubmit={handleFormSubmit}
            initialData={documentId ? document : {}}
          >
            <IonRow className="ion-justify-content-center">
              <IonCol sizeMd="6">
                <Select
                  name="documentCategoryId"
                  options={documentCategories}
                  label="Category"
                  placeholder="Choose a category"
                />
              </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center">
              <IonCol sizeMd="6">
                <Input
                  name="name"
                  label="Name"
                  placeholder="Enter document name"
                />
              </IonCol>
            </IonRow>
            <IonRow
              className="ion-justify-content-center"
              style={{ marginTop: "20px" }}
            >
              <IonCol sizeMd="6">
                <File
                  id="file"
                  name="file"
                  label="File"
                  buttonLabel="Select file"
                  accept={[
                    ".doc",
                    ".docx",
                    "application/msword",
                    "application/pdf",
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    "image/*",
                  ]}
                  onChange={(e) => setFile(e)}
                />
              </IonCol>
            </IonRow>
            <IonRow
              className="ion-justify-content-center"
              style={{ marginTop: "20px" }}
            >
              <IonCol sizeMd="6">
                <Button label="Upload" type="submit" isLoading={loading} />
              </IonCol>
            </IonRow>
          </Form>
        </IonGrid>
      </IonContent>
    </Layout>
  );
};
