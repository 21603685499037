import {
  IonAlert,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonLabel,
  IonRow,
  useIonRouter,
} from "@ionic/react";
import moment from "moment";
import { add, trash } from "ionicons/icons";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import { useContext, useRef, useState } from "react";
import * as Yup from "yup";
import { Input } from "../../components/elements/form/Input";
import Layout from "../../components/elements/Layout";
import { AxiosClient } from "../../services/api";
import { Button } from "../../components/elements/form/Button";
import { useParams } from "react-router";
import { NavigationContext } from "../../contexts/NavigationContext";
import { useQuery } from "@tanstack/react-query";
import { Select } from "../../components/elements/form/Select";
import { Datetime } from "../../components/elements/form/Datetime";
import { AuthContext } from "../../contexts/AuthContext";

interface MilestoneData {
  id?: number;
  milestoneCategoryId: string;
  milestonesActivities?: MilestoneActivityData[];
}

interface MilestoneActivityData {
  id?: number;
  activityName: string;
  activityDate: Date;
}

interface MilestoneCategoryTemplate {
  name: string;
  milestoneCategoryId: number;
}

export const MilestonesForm: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [activities, setActivities] = useState<MilestoneActivityData[]>([]);
  const { milestoneId } = useParams<{ milestoneId?: string }>();
  const { school } = useContext(NavigationContext);
  const { user } = useContext(AuthContext);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [buttonText, setButtonText] = useState("Save as template");
  const router = useIonRouter();

  const { isFetching, data: milestone } = useQuery(
    ["milestone"],
    getMilestone,
    {
      enabled: !!milestoneId,
      staleTime: Infinity,
    }
  );

  const { isFetching: isFetchingCategories, data: milestoneCategories } =
    useQuery(["milestone-categories"], getMilestoneCategories, {
      staleTime: Infinity,
    });

  async function getMilestoneCategories() {
    const response = await AxiosClient.post("/milestones-categories/find-all", {
      include: {
        milestoneCategoryTemplates: true,
      },
    });

    return {
      milestoneCategories: response.data.map((category: any) => ({
        value: category.id,
        label: category.name,
      })),
      milestoneCategoryTemplates: response.data.map((category: any) => ({
        categoryId: category.id,
        templates: category.milestoneCategoryTemplates,
      })),
    };
  }

  async function getMilestone() {
    const response = await AxiosClient.post(`/milestones/${milestoneId}`, {
      include: {
        milestonesActivities: true,
      },
    });

    const milestone = {
      milestoneCategoryId: response.data.milestoneCategoryId,
      milestonesActivities: response.data.milestonesActivities.map(
        (activity: any) => ({
          id: activity.id,
          activityName: activity.name,
          activityDate: activity.deadline
            ? moment(activity.deadline).format("MM/DD/YYYY")
            : null,
        })
      ),
    };

    setActivities(milestone.milestonesActivities);

    return milestone;
  }

  function changeTemplate(e: any) {
    const categoryId = e.target.value;
    if (categoryId) {
      const categoryTemplate =
        milestoneCategories?.milestoneCategoryTemplates.find(
          (template: any) => template.categoryId === parseInt(categoryId)
        );

      if (categoryTemplate.templates.length > 0) {
        const activities = categoryTemplate.templates.map((template: any) => ({
          activityName: template.name,
          activityDate: null,
        }));
        setActivities(activities);
        setTimeout(() => {
          formRef.current?.setData({
            milestonesActivities: activities,
          });
        }, 300);
      } else {
        setActivities([]);
      }
    }
  }

  async function handleFormSubmit(data: MilestoneData) {
    try {
      formRef.current?.setErrors({});

      let shape = {
        milestoneCategoryId: Yup.string()
          .test("has-activities", "Must contain at least one activity", () => {
            if (
              data.milestonesActivities &&
              data.milestonesActivities.length > 0
            ) {
              return true;
            }
            return false;
          })
          .required("Category is required"),
      } as any;

      const schema = Yup.object().shape(shape);

      await schema.validate(data, {
        abortEarly: false,
      });

      const milestoneData = {
        milestoneCategory: {
          connect: { id: parseInt(data.milestoneCategoryId) },
        },
        school: {
          connect: { id: school },
        },
      };

      if (data.milestonesActivities) {
        const activities = data.milestonesActivities?.map((activity) => ({
          name: activity.activityName,
          deadline: activity.activityDate
            ? moment(activity.activityDate)
            : null,
        }));

        const milestonesActivities = {
          create: activities,
        };

        Object.assign(milestoneData, { milestonesActivities });
      }

      if (milestoneId) {
        await AxiosClient.put(`/milestones/${milestoneId}`, milestoneData);
      } else {
        await AxiosClient.post("/milestones", milestoneData);
      }

      return router.push("/app/milestones", "forward", "push", {
        unmount: true,
      });
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {} as any;

        err.inner.forEach((error) => {
          errorMessages[error.path!] = error.message;
        });
        console.log(errorMessages);
        formRef.current!.setErrors(errorMessages);
      }
    }
  }

  async function createTemplate() {
    const formData = formRef.current?.getData();
    const template = [] as MilestoneCategoryTemplate[];
    if (formData?.milestoneCategoryId) {
      for (const index in formData?.milestonesActivities) {
        if (formData?.milestonesActivities[index].activityName) {
          template.push({
            name: formData?.milestonesActivities[index].activityName,
            milestoneCategoryId: parseInt(formData.milestoneCategoryId),
          });
        }
        if (template.length > 0) {
          await AxiosClient.post("milestones-categories/create-template", {
            templates: template,
          });
          setButtonText("Template created");
          setTimeout(() => {
            setButtonText("Save as template");
          }, 1000);
        }
      }
    } else {
      setButtonText("Please select a category");
      setTimeout(() => {
        setButtonText("Save as template");
      }, 1000);
    }
  }

  return (
    <Layout
      title="Milestones Form"
      showBackButton
      showSchools={false}
      isLoading={isFetchingCategories && (milestoneId ? isFetching : false)}
    >
      <IonContent fullscreen>
        <IonGrid fixed={true}>
          <Form
            ref={formRef}
            onSubmit={handleFormSubmit}
            initialData={milestoneId ? milestone : {}}
          >
            <IonRow className="ion-justify-content-center">
              <IonCol sizeMd="6">
                <Select
                  name="milestoneCategoryId"
                  options={milestoneCategories?.milestoneCategories}
                  label="Category"
                  placeholder="Choose a category"
                  onChange={changeTemplate}
                />
              </IonCol>
            </IonRow>
            {user && user.role === 1 && activities.length > 0 && (
              <IonRow className="ion-justify-content-center">
                <IonCol sizeMd="6">
                  <Button
                    size="small"
                    label={buttonText}
                    color="primary"
                    onClick={createTemplate}
                  />
                </IonCol>
              </IonRow>
            )}
            {activities.map((activity, index) => (
              <div key={index} style={{ marginTop: "40px" }}>
                <IonRow className="ion-justify-content-center">
                  <IonCol sizeMd="6">
                    <IonLabel style={{ marginLeft: "10px" }}>
                      Activity {index + 1}{" "}
                      <IonIcon
                        color="danger"
                        icon={trash}
                        onClick={() => {
                          const newActivities = activities.filter(
                            (ac, i) => activity.id !== ac.id
                          );
                          formRef.current?.setData({
                            milestonesActivities: newActivities,
                          });
                          setActivities(newActivities);
                        }}
                      ></IonIcon>
                    </IonLabel>
                    <Input
                      name={`milestonesActivities.${index}.activityName`}
                      label="Name"
                      className="activityName"
                    />
                  </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-center">
                  <IonCol sizeMd="6" style={{ alignItems: "start" }}>
                    <Datetime
                      id={`milestonesActivities.${index}.activityDate`}
                      name={`milestonesActivities.${index}.activityDate`}
                      label="Date"
                      className="activityDate"
                    />
                  </IonCol>
                </IonRow>
              </div>
            ))}
            <IonRow>
              <IonCol pushMd="3" sizeMd="2">
                <IonButton
                  onClick={() =>
                    setActivities([...activities, {} as MilestoneActivityData])
                  }
                >
                  <IonIcon slot="start" icon={add}></IonIcon>
                  Add Activity
                </IonButton>
              </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center">
              <IonCol sizeMd="6">
                <Button label="Save" type="submit" />
              </IonCol>
            </IonRow>
            {milestoneId && (
              <IonRow className="ion-justify-content-center">
                <IonCol sizeMd="6">
                  <IonButton
                    color="danger"
                    expand="block"
                    onClick={() => setShowDeleteAlert(true)}
                  >
                    <IonIcon slot="start" icon={trash}></IonIcon>
                    Delete
                  </IonButton>
                </IonCol>
                <IonAlert
                  isOpen={showDeleteAlert}
                  onDidDismiss={() => setShowDeleteAlert(false)}
                  header="Delete Milestone"
                  subHeader="Sure you want to delete this milestone?"
                  buttons={[
                    {
                      text: "Cancel",
                      role: "cancel",
                    },
                    {
                      text: "Delete",
                      role: "confirm",
                      handler: () => {
                        AxiosClient.delete(`/milestones/${milestoneId}`);
                        router.push("/app/milestones", "forward", "push", {
                          unmount: true,
                        });
                      },
                    },
                  ]}
                />
              </IonRow>
            )}
          </Form>
        </IonGrid>
      </IonContent>
    </Layout>
  );
};
